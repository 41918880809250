var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadedBookmarks
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            {
              class: {
                "pa-1": _vm.$vuetify.breakpoint.xsOnly,
                "pa-2": _vm.$vuetify.breakpoint.smOnly,
                "pa-3": _vm.$vuetify.breakpoint.mdOnly,
                "pa-4": _vm.$vuetify.breakpoint.lgOnly,
                "pa-5": _vm.$vuetify.breakpoint.xlOnly
              },
              attrs: { row: "", wrap: "" }
            },
            [
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm10: "",
                    lg8: "",
                    "align-center": "",
                    "justify-center": ""
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: "",
                        elevation: _vm.$vuetify.breakpoint.xsOnly ? 0 : 3
                      }
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "mb-3",
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _c("v-flex", [
                                _c(
                                  "h1",
                                  {
                                    staticClass:
                                      "title font-weight-light primary--text"
                                  },
                                  [_vm._v("Goals")]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            _vm._l(_vm.loadedBookmarks.goals, function(
                              goal,
                              index
                            ) {
                              return _c(
                                "v-flex",
                                {
                                  key: goal.id,
                                  class: { "mt-3": index === 0 },
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c("goal-card", {
                                    attrs: { goal: goal, isHome: true }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "mb-3",
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _c("v-flex", [
                                _c(
                                  "h1",
                                  {
                                    staticClass:
                                      "title font-weight-light primary--text"
                                  },
                                  [_vm._v("Thoughts")]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            _vm._l(_vm.loadedBookmarks.thoughts, function(
                              thought,
                              index
                            ) {
                              return _c(
                                "v-flex",
                                {
                                  key: thought.id,
                                  class: { "mt-3": index === 0 },
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c("thought-card", {
                                    attrs: { thought: thought, isHome: true }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "mb-3",
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _c("v-flex", [
                                _c(
                                  "h1",
                                  {
                                    staticClass:
                                      "title font-weight-light primary--text"
                                  },
                                  [_vm._v("Moods")]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            _vm._l(_vm.loadedBookmarks.moods, function(
                              mood,
                              index
                            ) {
                              return _c(
                                "v-flex",
                                {
                                  key: mood.id,
                                  class: { "mt-3": index === 0 },
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c("mood-card", {
                                    attrs: { mood: mood, isHome: true }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }