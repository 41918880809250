<template>
<v-container fluid v-if="loadedBookmarks">
  <v-layout row wrap :class="{'pa-1':$vuetify.breakpoint.xsOnly,
                              'pa-2':$vuetify.breakpoint.smOnly,
                              'pa-3':$vuetify.breakpoint.mdOnly,
                              'pa-4':$vuetify.breakpoint.lgOnly,
                              'pa-5':$vuetify.breakpoint.xlOnly}">
    <v-flex xs12 sm10 lg8 align-center justify-center>
      <v-card flat :elevation="$vuetify.breakpoint.xsOnly?0:3">
        <v-container fluid>
          <v-layout row wrap class="mb-3">
            <v-flex>
              <h1 class="title font-weight-light primary--text">Goals</h1>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 v-for="(goal, index) of loadedBookmarks.goals" :key="goal.id" :class="{'mt-3':index === 0}">
              <goal-card :goal="goal" :isHome="true"></goal-card>
            </v-flex>
          </v-layout>
        </v-container>

        <v-container fluid>
          <v-layout row wrap class="mb-3">
            <v-flex>
              <h1 class="title font-weight-light primary--text">Thoughts</h1>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 v-for="(thought,index) of loadedBookmarks.thoughts" :key="thought.id"
                    :class="{'mt-3':index === 0}">
              <thought-card :thought="thought" :isHome="true"></thought-card>
            </v-flex>
          </v-layout>
        </v-container>

        <v-container fluid>
          <v-layout row wrap class="mb-3">
            <v-flex>
              <h1 class="title font-weight-light primary--text">Moods</h1>
            </v-flex>
          </v-layout>
          <v-layout row wrap>

            <v-flex xs12 v-for="(mood,index) of loadedBookmarks.moods" :key="mood.id" :class="{'mt-3':index === 0}">
              <mood-card :mood="mood" :isHome="true"></mood-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
import thoughtCard from '@/views/tools/thoughts/components/thought-card.vue';
import goalCard from '@/views/tools/goals/components/goal-card.vue';
import moodCard from '@/views/tools/moods/components/moodCard/mood-card.vue';

export default {
  name: 'bookmarks',
  components: {
    thoughtCard, goalCard, moodCard,
  },
  props: {
    bookmarks: {
      type: Promise,
      required: true,
    },
  },
  data() {
    return {
      loadedBookmarks: null,
    };
  },
  mounted() {
    this.bookmarks.then(res => {
      this.loadedBookmarks = res;
    });
  },
};
</script>
